<template>
  <div class="layout">
    <Layout>
      <Sider :collapsible="true" breakpoint="lg" hide-trigger ref="sider" v-model="isCollapsed">
        <div class="logo">
          <!-- <img class="logo-icon" src="../assets/logo.png" alt="" />
          <span class="logo-text">Beike</span>-->
          <span class="logo-text">实验室信息管理系统</span>
        </div>
        <div class="left-menu-loading" v-if="userLoading">加载中...</div>
        <Menu
          :active-name="$route.meta.activeName"
          :open-names="openNames"
          @on-select="toPage"
          accordion
          mode="vertical"
          ref="siderMenu"
          theme="dark"
          v-if="!userLoading"
        >
          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Btms"
          >
            <MenuItem name="icon">
              <Icon type="md-list-box" />
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'order'"
                name="order"
                v-if="$able.map.BtmsOrder"
              >委托单</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'orderItems'"
                name="orderItems"
                v-if="$able.map.BtmsTestItem"
              >测试项目</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'testingSyllabus'"
                name="testingSyllabus"
                v-if="$able.map.BtmsSyllabus"
              >测试大纲</DropdownItem>
              <!-- <DropdownItem
                :selected="$route.name == 'bom'"
                name="bom"
                v-if="$able.map.BtmsBom"
              >物料清单</DropdownItem> -->
              <DropdownItem
                :selected="$route.name == 'bomSend'"
                name="bomSend"
                v-if="$able.map.BtmsBomSendAndRec"
              >物料寄送</DropdownItem>
              <!-- <DropdownItem
                :selected="$route.name == 'startBill'"
                name="startBill"
                v-if="$able.map.BtmsStartBill"
              >测试流转单</DropdownItem> -->
              <DropdownItem
                :selected="$route.name == 'cashCollectionPlan'"
                name="cashCollectionPlan"
                v-if="$able.map.BtmsCashColl"
              >回款计划</DropdownItem>
              <!-- <DropdownItem name="order" :selected="$route.name == 'invoiceSend'">发票寄送</DropdownItem> -->
            </DropdownMenu>
          </Dropdown>
          <Submenu name="orders" v-if="!isCollapsed&&$able.map.Btms">
            <template slot="title">
              <Icon type="md-list-box" />
              <span class="name">订单</span>
            </template>
            <MenuItem name="order" v-if="$able.map.BtmsOrder">
              <span class="name">委托单</span>
            </MenuItem>
            <MenuItem name="orderItems" v-if="$able.map.BtmsTestItem">
              <span class="name">测试项目</span>
            </MenuItem>
            <MenuItem name="testingSyllabus" v-if="$able.map.BtmsSyllabus">
              <span class="name">测试大纲</span>
            </MenuItem>
            <!-- <MenuItem name="bom" v-if="$able.map.BtmsBom">
              <span class="name">物料清单</span>
            </MenuItem> -->
            <MenuItem name="bomSend" v-if="$able.map.BtmsBomSendAndRec">
              <span class="name">物料寄送</span>
            </MenuItem>
            <!-- <MenuItem name="startBill" v-if="$able.map.BtmsStartBill">
              <span class="name">测试流转单</span>
            </MenuItem> -->
            <MenuItem name="cashCollectionPlan" v-if="$able.map.BtmsCashColl">
              <span class="name">回款计划</span>
            </MenuItem>
            <!-- <MenuItem name="invoiceSend">
            <span class="name">发票寄送</span>
            </MenuItem>-->
          </Submenu>

          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Wms"
          >
            <MenuItem name="icon">
              <Icon type="md-battery-charging" />
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'goods'"
                name="goods"
                v-if="$able.map.WmsGoods"
              >样品&物料</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'stock'"
                name="stock"
                v-if="$able.map.WmsStock"
              >库存</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'stockIn'"
                name="stockIn"
                v-if="$able.map.WmsStockIn"
              >入库</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'stockOut'"
                name="stockOut"
                v-if="$able.map.WmsStockOut"
              >出库</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'storage'"
                name="storage"
                v-if="$able.map.WmsStorage"
              >仓库</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'prototypesDeal'"
                name="prototypesDeal"
                v-if="$able.map.WmsDeal"
              >样品处理</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Submenu name="goods" v-if="!isCollapsed&&$able.map.Wms">
            <template slot="title">
              <Icon type="md-battery-charging" />
              <span class="name">样品管理</span>
            </template>
            <MenuItem name="goods" v-if="$able.map.WmsGoods">
              <span class="name">样品&物料</span>
            </MenuItem>
            <MenuItem name="stock" v-if="$able.map.WmsStock">
              <span class="name">库存</span>
            </MenuItem>
            <MenuItem name="stockIn" v-if="$able.map.WmsStockIn">
              <span class="name">入库</span>
            </MenuItem>
            <MenuItem name="stockOut" v-if="$able.map.WmsStockOut">
              <span class="name">出库</span>
            </MenuItem>
            <MenuItem name="storage" v-if="$able.map.WmsStorage">
              <span class="name">仓库</span>
            </MenuItem>
            <MenuItem name="prototypesDeal" v-if="$able.map.WmsDeal">
              <span class="name">样品处理</span>
            </MenuItem>
          </Submenu>

          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Equ"
          >
            <MenuItem name="icon">
              <Icon type="md-speedometer" />
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'equipment'"
                name="equipment"
                v-if="$able.map.EquLst"
              >仪器列表</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'equipmentMro'"
                name="equipmentMro"
                v-if="$able.map.EquMro"
              >维修单</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'equipmentUseTrace'"
                name="equipmentUseTrace"
                v-if="$able.map.EquUse"
              >使用记录</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Submenu name="equipment" v-if="!isCollapsed&&$able.map.Equ">
            <template slot="title">
              <Icon type="md-speedometer" />
              <span class="name">仪器管理</span>
            </template>
            <MenuItem name="equipment" v-if="$able.map.EquLst">
              <span class="name">仪器列表</span>
            </MenuItem>
            <MenuItem name="equipmentMro" v-if="$able.map.EquMro">
              <span class="name">维修单</span>
            </MenuItem>
            <MenuItem name="equipmentUseTrace" v-if="$able.map.EquUse">
              <span class="name">使用记录</span>
            </MenuItem>
          </Submenu>

          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Crm"
          >
            <MenuItem name="icon">
              <Icon type="md-pricetag" />
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'crmOrder'"
                name="crmOrder"
                v-if="$able.map.CrmOrder"
              >销售跟单</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'crmTrader'"
                name="crmTrader"
                v-if="$able.map.CrmTrader"
              >往来单位</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Submenu name="crm" v-if="!isCollapsed&&$able.map.Crm">
            <template slot="title">
              <Icon type="md-pricetag" />
              <span class="name">客户关系</span>
            </template>
            <MenuItem name="crmOrder" v-if="$able.map.CrmOrder">
              <span class="name">销售跟单</span>
            </MenuItem>
            <MenuItem name="crmTrader" v-if="$able.map.CrmTrader">
              <span class="name">往来单位</span>
            </MenuItem>
          </Submenu>

          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Org"
          >
            <MenuItem name="icon">
              <Icon type="md-people" />
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'dept'"
                name="dept"
                v-if="$able.map.OrgDept"
              >部门</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'user'"
                name="user"
                v-if="$able.map.OrgUser"
              >人员管理</DropdownItem>
              <DropdownItem
                :selected="$route.name == 'guest'"
                name="guest"
                v-if="$able.map.OrgGuest"
              >客户帐号</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Submenu name="org" v-if="!isCollapsed&&$able.map.Org">
            <template slot="title">
              <Icon type="md-people"></Icon>
              <span class="name">人员管理</span>
            </template>
            <MenuItem name="dept" v-if="$able.map.OrgDept">
              <span class="name">部门</span>
            </MenuItem>
            <MenuItem name="user" v-if="$able.map.OrgUser">
              <span class="name">人员管理</span>
            </MenuItem>
            <MenuItem name="guest" v-if="$able.map.OrgGuest">
              <span class="name">客户帐号</span>
            </MenuItem>
          </Submenu>

          <MenuItem name="survey" v-if="$able.map.SurveyMgr">
            <Icon type="md-paper"></Icon>
            <span class="name" v-if="!isCollapsed">问卷管理</span>
          </MenuItem>

          <Dropdown
            @on-click="toPage"
            placement="right-start"
            transfer-class-name="sider-menu-dropdown"
            v-if="isCollapsed&&$able.map.Sys"
          >
            <MenuItem name="icon">
              <Icon type="md-cog"></Icon>
            </MenuItem>
            <DropdownMenu slot="list">
              <DropdownItem
                :selected="$route.name == 'role'"
                name="role"
                v-if="$able.map.SysRole"
              >角色授权</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Submenu name="sys" v-if="!isCollapsed&&$able.map.Sys">
            <template slot="title">
              <Icon type="md-cog"></Icon>
              <span class="name">系统设置</span>
            </template>
            <MenuItem name="role" v-if="$able.map.SysRole">
              <span class="name">角色列表</span>
            </MenuItem>
          </Submenu>
        </Menu>
      </Sider>

      <Layout class="main-layout">
        <Header>
          <div @click="collapsedSider" class="header-trigger">
            <Icon :class="menuIcon" size="20" type="md-menu" />
          </div>
          <div @click="refresh" class="header-trigger" v-if="!userLoading">
            <Icon size="20" type="md-refresh" />
          </div>
          <Divider type="vertical" v-if="$store.state.order.Id" />
          <div class="header-item order-locate" v-if="$store.state.order.Id">
            <Tag @on-close="offOrderLocate" closable color="blue">委托单：{{ $store.state.order.Code }}</Tag>
            <Tooltip max-width="300">
              <Icon color="#808695" style="cursor: pointer" type="md-help-circle" />
              <div slot="content">固定指定委托单作为其他功能模块的快捷查询条件</div>
            </Tooltip>
          </div>
          <Divider type="vertical" v-if="$store.state.order.Id" />
          <div class="flex items-center" style="float: right">
            <a
              class="header-item text-underline"
              href="https://www.beikekj.com/?fsrc=btr"
              style="font-size:12px;float:right;"
              target="_blank"
            >贝壳科技提供技术支持</a>
            <Divider type="vertical" v-if="$store.state.order.Id" />
            <div class="header-trigger" v-if="$store.state.user">
              <Dropdown @on-click="onAccount" placement="bottom-end">
                <div>
                  <Avatar
                    :src="$store.state.user.Avatar"
                    size="small"
                    style="margin-top:-5px;"
                    v-if="$store.state.user.Avatar"
                  ></Avatar>
                  <Avatar icon="ios-person" size="small" v-if="!$store.state.user.Avatar"></Avatar>
                  <span class="username">{{ $store.state.user.UserName }}</span>
                </div>
                <DropdownMenu slot="list">
                  <DropdownItem name="updPwd">修改密码</DropdownItem>
                  <DropdownItem divided name="logout">退出登录</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content>
          <Spin fix v-if="userLoading">
            <Icon class="demo-spin-icon-load" size="18" type="ios-loading"></Icon>
            <div>Loading</div>
          </Spin>
          <div class="tabs-main" v-if="!userLoading">
            <Tabs
              :before-remove="beforeOffTab"
              :value="$store.state.tabName"
              @on-click="onTab"
              closable
              type="card"
            >
              <TabPane
                :closable="tab.name != 'home'"
                :key="index"
                :label="tab.label"
                :name="tab.name"
                v-for="(tab, index) in $store.state.tabs"
              ></TabPane>
              <div slot="extra">
                <Dropdown @on-click="onTabsExtra" placement="bottom-end" trigger="click">
                  <div class="tabs-extra">
                    <Icon type="ios-arrow-down"></Icon>
                  </div>
                  <DropdownMenu slot="list">
                    <DropdownItem name="close-left">
                      <Icon type="md-arrow-back" />关闭左侧
                    </DropdownItem>
                    <DropdownItem name="close-right">
                      <Icon type="md-arrow-forward" />关闭右侧
                    </DropdownItem>
                    <DropdownItem name="close-other">
                      <Icon type="md-close" />关闭其他
                    </DropdownItem>
                    <DropdownItem name="close-all">
                      <Icon type="md-close-circle" />全部关闭
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Tabs>
          </div>
          <div class="main-content" v-if="!userLoading">
            <!-- <div style="padding: 10px"> -->
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
            <!-- </div> -->
          </div>
        </Content>
      </Layout>
    </Layout>
    <upd-pwd ref="updPwd" />
  </div>
</template>
<script>
import UpdPwd from "../components/UpdPwd.vue";
export default {
  components: { UpdPwd },
  data() {
    return {
      isCollapsed: false,
      openNames: [],
      userLoading: true,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate", from.name);
    next();
    this.openNames = [to.meta.openName];
    if (this.$refs.siderMenu && to.meta.openName) {
      this.$nextTick(() => {
        console.log("openNames", this.openNames);
        this.$refs.siderMenu.updateOpened();
      });
    }
    // console.log("after next ", to.name);
  },
  mounted: function () {
    this.reqUseerSession();
    document.getElementsByTagName("title")[0].innerHTML =
      "倍特睿实验室信息管理系统";
  },
  computed: {
    menuIcon() {
      return ["menu-icon", this.isCollapsed ? "rotate-icon" : ""];
    },
  },
  methods: {
    reqUseerSession() {
      this.userLoading = true;
      this.$axios
        .get("/Api/BeikeUser/UserSession")
        .then((res) => {
          if (res.result) {
            let user = res.data;
            // 先不存入缓存，tab.init需要已缓存的user数据判断当前用户是否一致，清楚相关缓存
            this.$store.commit("setUser", user);
            this.$tab.init(this, this.$route.name);
            this.$able.set(res.data.OpModuleCodes);
          } else {
            this.$Message.error(res.errormsg);
            this.userLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
          this.userLoading = false;
        });
    },
    //
    // 侧边栏
    toPage(name) {
      if (name && name != "icon" && name != this.$route.name) {
        this.$tab.open(this, name, { go: 1 });
      }
    },
    collapsedSider() {
      console.log("改变Sider展开-收起状态");
      this.$refs.sider.toggleCollapse();
      this.openNames = [];
      this.$nextTick(() => {
        this.$refs.siderMenu.updateOpened();
      });
    },
    //
    onTab(name) {
      this.$tab.open(this, name, { go: 1 });
    },
    beforeOffTab(index) {
      return new Promise((resolver, reject) => {
        let name = this.$store.state.tabs[index].name;
        this.$tab.close(this, name);
        // resolver();
      }).catch((e) => {});
    },
    offTab(name) {
      this.$tab.close(this, name);
    },
    onTabsExtra(name) {
      let tabName = this.$store.state.tabName;
      if (!tabName && name != "close-all") {
        return;
      }
      if (name == "close-left") {
        this.$tab.closeLeft(this, tabName);
      } else if (name == "close-right") {
        this.$tab.closeRight(this, tabName);
      } else if (name == "close-other") {
        this.$tab.closeOther(this, tabName);
      } else if (name == "close-all") {
        this.$tab.closeAll(this);
      }
    },
    // 刷新
    refresh() {
      this.$root.$emit("on-refresh");
    },
    // 去除快速定位
    offOrderLocate() {
      this.$store.commit("setOrderLocate", "");
      this.$util.clearSession("order-locate");
    },
    //
    onAccount(name) {
      if (name == "updPwd") {
        this.$refs.updPwd.open();
      } else if (name == "logout") {
        this.$able.set();
        this.$axios.get("/Home/Logout");
        this.$util.clearSession("user");
        this.$util.clearSession("tabs");
        this.$util.clearSession("tab-opts");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style lang="less">
@import "index.less";
</style>