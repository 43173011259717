<template>
    <div id="updPwd">
        <Modal title="修改密码" v-model="flag" @on-cancel="close" width="400">
            <Form @submit.native.prevent :model="formd" :rules="rules" ref="form" :label-width="130">
                <FormItem label="旧密码：" prop="OldPassword">
                    <Input v-model="formd.OldPassword" type="password" />
                </FormItem>
                <FormItem label="新密码：" prop="Password">
                    <Input v-model="formd.Password" type="password" />
                </FormItem>
                <FormItem label="确认密码：" prop="Password2">
                    <Input v-model="formd.Password2" type="password" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" @click="close">取消</Button>
                <Button type="primary" @click="ok" :loading="posting">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Password: [{ required: true, message: " ", trigger: "blur,change" }],
        Password2: [
          { required: true, message: " ", trigger: "blur,change" },
          {
            validator: (rule, value, callback) => {
              this.formd.Password == this.formd.Password2
                ? callback()
                : callback(new Error("二次输入密码不一致"));
            },
            trigger: "blur,change"
          }
        ],
        OldPassword: [{ required: true, message: " ", trigger: "blur,change" }]
      }
    };
  },
  methods: {
    open() {
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/api/BeikeUser/UpdateMyPassword", {
          Password: this.formd.Password,
          OldPassword: this.formd.OldPassword
        })
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("修改成功");
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    }
  }
};
</script>

